import { WeatherIndicator } from "../components/WeatherIndicator";
import "../assets/scss/pages/PageBattery.scss";
import Battery from "../components/Battery";
import {
  Box,
  Button,
  Flex,
  HStack,
  Show,
  Skeleton,
  Slider,
  SliderFilledTrack,
  SliderMark,
  SliderThumb,
  SliderTrack,
  Text,
  useToast,
} from "@chakra-ui/react";
import { ReactComponent as Bolt } from "../assets/img/Bolt.svg";
import {
  COLORS,
  getBatteryCapacity,
  getCapacityCharge,
  getCapacityDeCharge,
  getNumberFormatter,
  handleError,
  intervalId,
  YYYY_MM_DD_HH_MM_SS,
} from "../utils/toolbox";
import {
  BoostType,
  useCreateBoostMutation,
  useGetCurrentBoostQuery,
  useGetLastBoostsQuery,
  useLastStateBatteryMutation,
  useStopBoostMutation,
} from "../services/ems.service";
import React, { useEffect, useState } from "react";
import { getSelectedTopo } from "../reducers/topologyReducer";
import { store } from "../store/config";
import { useNavigate } from "react-router-dom";
import { BatteryIndicators } from "../components/BatteryIndicators";
import { BatteryGraphs } from "../components/BatteryGraphs";
import { ResponsiveLayout } from "../layouts/ResponsiveLayout";
import EmsAlert from "../components/EmsAlert";
import { ErrorBoundary } from "react-error-boundary";
import ErrorFallback from "../components/ErrorFallback";
import moment from "moment";
export const PageBattery: React.FC = () => {
  const [
    getBatteryData,
    {
      data: batteryData,
      isError: batteryDataIsError,
      error: batteryDataError,
      isLoading: batteryDataIsFetching,
    },
  ] = useLastStateBatteryMutation();

  const selectedTopo = getSelectedTopo(store.getState());
  const [, setTimeRemaining] = useState(intervalId);
  const navigate = useNavigate();

  useEffect(() => {
    if (selectedTopo.id == "") {
      navigate("/redirect");
    }
  }, [selectedTopo]);
  useEffect(() => {
    getBatteryData({ topologyId: selectedTopo.id, date: new Date() });
    let today = new Date();
    const timer1 = setInterval(() => {
      getBatteryData({ topologyId: selectedTopo.id, date: new Date() });
      setTimeRemaining(intervalId);
      today = new Date();
    }, intervalId * 60 * 1000);
    const start = today.getSeconds();
    const timer2 = setInterval(() => {
      if (new Date().getSeconds() >= start) {
        setTimeRemaining(60 - (new Date().getSeconds() - start));
      } else {
        setTimeRemaining(60 - (60 - start + new Date().getSeconds()));
      }
    }, 1000);
    return () => {
      window.clearInterval(timer1);
      window.clearInterval(timer2);
    };
  }, [selectedTopo]);
  const { data: lastBoosts, refetch } = useGetLastBoostsQuery(
    {
      fluxTopologyId: selectedTopo.id,
      date: moment().format("YYYY-MM-DDTHH:mm"),
    },
    { refetchOnMountOrArgChange: true }
  );
  const [sliderValue, setSliderValue] = useState(50);

  const labelStyles = {
    mt: "2",
    ml: "-2.5",
    fontSize: "sm",
  };
  const [batteryValue, setBatteryValue] = useState(0);
  useEffect(() => {
    setBatteryValue(() => batteryData?.sBat || 0);
  }, [batteryData?.sBat]);
  useEffect(() => {
    setSliderValue(
      (() => {
        let boosts = lastBoosts?.filter((x) =>
          moment(x.endDate).isAfter(new Date())
        );

        if (boosts && boosts?.length > 0) {
          return boosts[0].targetSoc || 50;
        }
        return 50;
      })()
    );
  }, [lastBoosts]);
  const [
    createBoost,
    { isError: createBoostIsError, isLoading: createBoostIsLoading },
  ] = useCreateBoostMutation();
  const [
    stopBoost,
    { isError: stopBoostIsError, isLoading: stopBoostIsLoading },
  ] = useStopBoostMutation();
  const [refresh, setRefresh] = useState(false);

  const handleCreateBoost = () => {
    createBoost({
      fluxTopologyId: Number(selectedTopo.id),
      boostDto: {
        boostType: BoostType.DISCHARGE,
        targetSoc: sliderValue,
      },
    })
      .unwrap()
      .then(() => {
        refetch();
        setRefresh((prev) => !prev);
        toast({
          title: "Boost Crée !",
          status: "success",
          duration: 4000,
          isClosable: true,
        });
      })
      .catch((err) => {
        toast({
          title: "Une erreur est surevenu !",
          status: "error",
          duration: 4000,
          isClosable: true,
        });
      });
  };
  const toast = useToast();
  const handleStopBoost = () => {
    stopBoost({
      fluxTopologyId: Number(selectedTopo.id),
    })
      .unwrap()
      .then(() => {
        refetch();
        setRefresh((prev) => !prev);
        toast({
          title: "Boost stoped !",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
      })
      .catch((err) => {
        toast({
          title: "Une erreur est surevenu !",
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      });
  };

  return (
    <>
      <Show above="md">
        <ResponsiveLayout
          header={
            <ErrorBoundary FallbackComponent={ErrorFallback}>
              <WeatherIndicator />
            </ErrorBoundary>
          }
          leftSide={
            batteryDataIsError && batteryDataError ? (
              <EmsAlert {...handleError(batteryDataError)}></EmsAlert>
            ) : (
              <ErrorBoundary FallbackComponent={ErrorFallback}>
                <Skeleton
                  isLoaded={!batteryDataIsFetching}
                  className="flex flex-col gap-2 items-center justify-start mx-auto w-full list"
                >
                  <div>
                    <BatteryIndicators
                      charge={
                        batteryData && selectedTopo.batteryCapacity
                          ? (
                              getCapacityCharge(
                                getBatteryCapacity(
                                  selectedTopo.batteryCapacity as string
                                ),
                                batteryValue as number
                              ) as number
                            ).toFixed(1) + " kWh"
                          : ""
                      }
                      decharge={
                        batteryData && selectedTopo.batteryCapacity
                          ? (
                              getCapacityDeCharge(
                                getBatteryCapacity(
                                  selectedTopo.batteryCapacity as string
                                ),
                                batteryValue as number
                              ) as number
                            ).toFixed(1) + " kWh"
                          : ""
                      }
                      nextCharge="12h"
                      isLoaded={!batteryDataIsFetching}
                    />
                  </div>
                  <div className="flex flex-col">
                    <Text className="max-w-fit m-auto p-1 text-center font-extrabold leading-tight mt-2">
                      {batteryData?.pBat !== undefined &&
                        batteryData?.pBat > 0 && <span>Charge</span>}
                      {batteryData?.pBat !== undefined &&
                        batteryData?.pBat < 0 && <span>Décharge</span>}
                    </Text>
                    <Text
                      color="#FFFFFF"
                      background={
                        batteryData?.pBat !== undefined
                          ? batteryData?.pBat > 0
                            ? COLORS.BATTERYUP
                            : batteryData?.pBat < 0
                            ? COLORS.BATTERYDOWN
                            : COLORS.BATTERYNONE
                          : COLORS.EMS_GREEN
                      }
                      className="rounded-full   max-w-fit  m-auto text-center font-extrabold leading-tight mt-2 py-3 px-4"
                    >
                      <Bolt className="inline mb-1 mr-2 " />
                      {batteryData?.pBat.toFixed(1)} kW
                    </Text>
                  </div>
                  <div className="flex flex-row justify-center ml-4">
                    <Battery
                      batteryValue={batteryValue}
                      className="h-[200px] md:h-[300px] mx-h-fit"
                    />
                  </div>
                  <div className="w-full">
                    <Text
                      color="#FFFFFF"
                      background={COLORS.EMS_BLUE}
                      className="rounded-full max-w-fit m-auto text-center font-extrabold leading-tight mt-2 py-3 px-4"
                    >
                      <Bolt className="inline mb-1 mr-2" />
                      {getNumberFormatter().format(batteryValue)} %
                    </Text>
                    <Box p={4} pt={12}>
                      <Slider
                        aria-label="slider-ex-6"
                        onChange={(val) => setSliderValue(val)}
                        value={sliderValue}
                      >
                        <SliderMark value={25} {...labelStyles}>
                          25%
                        </SliderMark>
                        <SliderMark value={50} {...labelStyles}>
                          50%
                        </SliderMark>
                        <SliderMark value={75} {...labelStyles}>
                          75%
                        </SliderMark>
                        <SliderMark
                          value={sliderValue}
                          textAlign="center"
                          bg={COLORS.EMS_BLUE}
                          color="white"
                          mt="-10"
                          ml="-5"
                          w="12"
                        >
                          {sliderValue}%
                        </SliderMark>
                        <SliderTrack>
                          <SliderFilledTrack bg={COLORS.EMS_BLUE} />
                        </SliderTrack>
                        <SliderThumb />
                      </Slider>
                      <HStack display={"flex"} justifyContent={"center"}>
                        <Button
                          onClick={handleCreateBoost}
                          bg={COLORS.EMS_BLUE}
                          color={"white"}
                          rounded={"full"}
                          _hover={{
                            bg: "none",
                            rounded: "full",
                          }}
                          mx={"auto"}
                          my={8}
                          display={"flex"}
                          isLoading={createBoostIsLoading}
                        >
                          Boost
                        </Button>
                        <Button
                          onClick={handleStopBoost}
                          bg={"red.500"}
                          color={"white"}
                          rounded={"full"}
                          _hover={{
                            bg: "none",
                            rounded: "full",
                          }}
                          mx={"auto"}
                          my={8}
                          display={"flex"}
                          isLoading={stopBoostIsLoading}
                          disabled={
                            !lastBoosts ||
                            lastBoosts?.length <= 0 ||
                            !lastBoosts.some((x) =>
                              moment(x.endDate).isAfter(new Date())
                            )
                          }
                        >
                          Stop Boost
                        </Button>
                      </HStack>
                    </Box>
                  </div>
                </Skeleton>
              </ErrorBoundary>
            )
          }
          rightSide={
            <Flex
              flex="2"
              flexDirection={"column"}
              className="p-4 overflow-y-auto list"
              gap={2}
              width={"100%"}
            >
              <ErrorBoundary FallbackComponent={ErrorFallback}>
                <BatteryGraphs />
              </ErrorBoundary>
            </Flex>
          }
        />
      </Show>
      <Show below="md">
        <ResponsiveLayout>
          {" "}
          <Flex
            flex="2"
            py={2}
            px={2}
            flexDirection={"column"}
            className="overflow-y-auto"
            gap={2}
          >
            {batteryDataIsError ? (
              <EmsAlert
                status="error"
                title={"Erreur lors de la récupération des données"}
                message={"Error"}
              ></EmsAlert>
            ) : (
              <ErrorBoundary FallbackComponent={ErrorFallback}>
                <Skeleton
                  isLoaded={!batteryDataIsFetching}
                  className="flex flex-col gap-2 items-center justify-start mx-auto w-full"
                >
                  <div>
                    <BatteryIndicators
                      charge={
                        batteryData && selectedTopo.batteryCapacity
                          ? (
                              getCapacityCharge(
                                getBatteryCapacity(
                                  selectedTopo.batteryCapacity as string
                                ),
                                batteryValue as number
                              ) as number
                            ).toFixed(1) + " kWh"
                          : ""
                      }
                      decharge={
                        batteryData && selectedTopo.batteryCapacity
                          ? (
                              getCapacityDeCharge(
                                getBatteryCapacity(
                                  selectedTopo.batteryCapacity as string
                                ),
                                batteryValue as number
                              ) as number
                            ).toFixed(1) + " kWh"
                          : ""
                      }
                      nextCharge="12h"
                      isLoaded={!batteryDataIsFetching}
                    />
                  </div>
                  <div className="flex flex-col">
                    <Text className="max-w-fit m-auto p-1 text-center font-extrabold leading-tight mt-2">
                      {batteryData?.pBat !== undefined &&
                        batteryData?.pBat > 0 && <span>Charge</span>}
                      {batteryData?.pBat !== undefined &&
                        batteryData?.pBat < 0 && <span>Décharge</span>}
                    </Text>
                    <Text
                      color="#FFFFFF"
                      background={
                        batteryData?.pBat !== undefined
                          ? batteryData?.pBat > 0
                            ? COLORS.BATTERYUP
                            : batteryData?.pBat < 0
                            ? COLORS.BATTERYDOWN
                            : COLORS.BATTERYNONE
                          : COLORS.EMS_GREEN
                      }
                      className="rounded-full   max-w-fit  m-auto text-center font-extrabold leading-tight mt-2 py-3 px-4"
                    >
                      <Bolt className="inline mb-1 mr-2 " />
                      {batteryData?.pBat.toFixed(1)} kW
                    </Text>
                  </div>
                  <div className="flex flex-row justify-center ml-4">
                    <Battery
                      batteryValue={batteryValue}
                      className="h-[200px] md:h-[300px] mx-h-fit"
                    />
                  </div>
                  <div className="">
                    <Text
                      color="#FFFFFF"
                      background={COLORS.EMS_BLUE}
                      className="rounded-full max-w-fit m-auto text-center font-extrabold leading-tight mt-2 py-3 px-4"
                    >
                      <Bolt className="inline mb-1 mr-2" />
                      {batteryValue} %
                    </Text>

                    <Box p={4} pt={12}>
                      <Slider
                        aria-label="slider-ex-6"
                        onChange={(val) => setSliderValue(val)}
                      >
                        <SliderMark value={25} {...labelStyles}>
                          25%
                        </SliderMark>
                        <SliderMark value={50} {...labelStyles}>
                          50%
                        </SliderMark>
                        <SliderMark value={75} {...labelStyles}>
                          75%
                        </SliderMark>
                        <SliderMark
                          value={sliderValue}
                          textAlign="center"
                          bg={COLORS.EMS_BLUE}
                          color="white"
                          mt="-10"
                          ml="-5"
                          w="12"
                        >
                          {sliderValue}%
                        </SliderMark>
                        <SliderTrack>
                          <SliderFilledTrack bg={COLORS.EMS_BLUE} />
                        </SliderTrack>
                        <SliderThumb />
                      </Slider>
                      <HStack display={"flex"} justifyContent={"center"}>
                        <Button
                          onClick={handleCreateBoost}
                          bg={COLORS.EMS_BLUE}
                          color={"white"}
                          rounded={"full"}
                          _hover={{
                            bg: "none",
                            rounded: "full",
                          }}
                          mx={"auto"}
                          my={8}
                          display={"flex"}
                          isLoading={createBoostIsLoading}
                        >
                          Boost
                        </Button>
                        <Button
                          onClick={handleStopBoost}
                          bg={"red.500"}
                          color={"white"}
                          rounded={"full"}
                          _hover={{
                            bg: "none",
                            rounded: "full",
                          }}
                          mx={"auto"}
                          my={8}
                          display={"flex"}
                          isLoading={stopBoostIsLoading}
                          disabled={
                            !lastBoosts ||
                            lastBoosts?.length <= 0 ||
                            !lastBoosts.some((x) =>
                              moment(x.endDate).isAfter(new Date())
                            )
                          }
                        >
                          Stop Boost
                        </Button>
                      </HStack>
                    </Box>
                  </div>
                </Skeleton>
              </ErrorBoundary>
            )}
            <ErrorBoundary FallbackComponent={ErrorFallback}>
              <BatteryGraphs />
            </ErrorBoundary>
          </Flex>
        </ResponsiveLayout>
      </Show>
    </>
  );
};
