import React, { useEffect, useRef, useState } from "react";
import { Bar } from "react-chartjs-2";
import "../assets/scss/components/LabeledBarChart.scss";
import { TimeLabels } from "../utils/toolbox";
import { CircularDiv } from "./CircularDiv";
import { Box, Flex } from "@chakra-ui/react";

type LabeledBarChartProps = {
  data: any;
  displayCols?: boolean;
  getDataForLabel?: (label: TimeLabels) => void;
  className?: string;
  withOpacity?:boolean
};

/**
 * Vous pouvez utiliser le paramètre displayCols à True pour afficher les pictogrammes sous forme de colonne
 * de haut en bas avec leurs labels (utilisé par exemple sur la page /indicators).
 */
const LabeledBarChart: React.FC<LabeledBarChartProps> = ({
  data: initialData,
  displayCols = false,
  getDataForLabel,
  className,
  withOpacity
}) => {
  const [data, setData] = useState(initialData);
  const chartRef = useRef<any>(null);

  const options: any = {
    maintainAspectRatio: false,
    responsive: true,
    type: "bar",
    scales: {
      x: {
        border: {
          display: false,
        },
        stacked: true,
        beginAtZero: true,
        ticks: {
          color: "#0487FB",
          font: {
            family: '"Titillium Web Regular"',
          },
        },
        grid: {
          display: false,
        },
      },
      y: {
        border: {
          display: false,
        },
        grid: {
          color: "#0487FB",
        },
        beginAtZero: true,
        stacked: true,
        ticks: {
          count: 10,
          callback: function (value: any, index: any, values: any) {
            return Math.round(value * 2) / 2;
          },
          color: "#0487FB",
          font: {
            family: '"Titillium Web Regular"',
          },
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },

      datalabels: {
        display: false,
      },
      tooltip: {
        mode: "index",
      },
    },
  };

  useEffect(() => {
    //setData(removeZeroValues(initialData))

    if (initialData) {
      setData({
        ...initialData,
        labels: initialData.labels.map((x: TimeLabels) => (x ? x.label : "")),
      });
    }
  }, [initialData]);

  const handleLegendClick = (index: number) => {
    if (chartRef && chartRef.current) {
      const chartInstance = chartRef.current;

      if (chartInstance) {
        const { datasets } = chartInstance.data;
        datasets
          .filter((x: any) => x.key == index)
          .forEach((dataset: any) => {
            const visibility = dataset.hidden;
            dataset.hidden =
              visibility === null || visibility === undefined
                ? true
                : !visibility;
          });

        chartInstance.update();

        setData({ ...data, datasets: datasets });
      }
    }
  };

  return (
    <Flex flexDirection={"column"} gap={2} h={"full"}>
      {!displayCols && (
        <Box>
          {data && data.datasets
            .map((dataset: any, index: number) => (
              <Box
                key={index}
                onClick={() => handleLegendClick(dataset.key)}
                className="labeled-bar-chart__legend__image"
              >
                <CircularDiv
                  color={dataset.hidden ? "grey" : dataset.bgColor}
                  icon={dataset.image}
                  withOpacity= {withOpacity}
                />
              </Box>
            ))}
        </Box>
      )}
      <Box className="h-[400px] md:h-full w-full">
        {data && <Bar
          className="h-full"
          ref={chartRef}
          data={data}
          options={options}
          //onClick={zoom}
        />}
      </Box>
      {displayCols && (
        <Box className="labeled-bar-chart__legend-col">
          {data && data.datasets
            .map((dataset: any, index: number) => (
              <Box key={index} className="labeled-bar-chart__legend-col-text">
                <Box
                  onClick={() => handleLegendClick(dataset.key)}
                  className="labeled-bar-chart__legend-col__image"
                >
                  <CircularDiv
                    color={dataset.hidden ? "grey" : dataset.bgColor}
                    icon={dataset.image}
                    withOpacity={withOpacity}
                  />
                </Box>
                {dataset.label}
              </Box>
            ))}
        </Box>
      )}
    </Flex>
  );
};

export default LabeledBarChart;
