import Panel from "../components/Panel";
import { DPEGES } from "../components/DPEGES";
import { EcoCoachingIndicators } from "../components/EcoCoachingIndicators";
import { WeatherIndicator } from "../components/WeatherIndicator";
import { ResponsiveLayout } from "../layouts/ResponsiveLayout";
import DpeGesSVG from "../components/DpeGesSVG";
import { COLORS, getLoadingDataErrorByStatus, handleError } from "../utils/toolbox";
import { Switcher } from "../components/Switcher";
import { useSwitcherContext } from "../contexts/SwitcherContext";
import { Tournesol } from "../components/Tournesol";
import "../assets/scss/pages/PageEcoCoaching.scss";
import { ScoreResponseType, coachingApi } from "../services/coaching.service";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getSelectedTopo } from "../reducers/topologyReducer";
import { store } from "../store/config";
import EmsAlert from "../components/EmsAlert";
import { Box, Flex, Link, Show, Skeleton } from "@chakra-ui/react";
import { ExternalLinkIcon } from "@chakra-ui/icons";
import { Unit } from "../services/ems.service";
export const PageEcoCoaching: React.FC = () => {
  const { unit, setUnit, setPeriod } = useSwitcherContext();
  const { useLazyGetScoreByTopoQuery } = coachingApi;
  const [
    getScoreByTopo,
    { isLoading: getScoreIsLoading, error: getScoreError },
  ] = useLazyGetScoreByTopoQuery();
  const selectedTopoId = getSelectedTopo(store.getState()).id;
  const navigate = useNavigate();
  const [scoreData, setScoreData] = useState<ScoreResponseType>();
  useEffect(() => {
    if (selectedTopoId == "") {
      navigate("/redirect");
    }
    setUnit(Unit.DPE);
    setPeriod("day");
  }, [selectedTopoId]);

  useEffect(() => {
    getScoreByTopo({ topoId: selectedTopoId })
      .unwrap()
      .then((res) => {
        setScoreData(res);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  return (
    <>
      <Show above="md">
        <ResponsiveLayout
          header={<WeatherIndicator />}
          leftSide={
            <Box flex="1" className="p-4 max-w-fit" overflow={"auto"}>
              <EcoCoachingIndicators
                meanBestHour={scoreData?.meanBestHour}
                meanSurplusHour={scoreData?.meanSurplusHour}
                meanWorstHour={scoreData?.meanWorstHour}
              />
              {getScoreError ? (
                <div className="max-h-fit">
                  <EmsAlert
                    {
                      ...handleError(getScoreError)
                     }
                  />
                </div>
              ) : (
                <Skeleton isLoaded={!getScoreIsLoading} className="mx-auto">
                  <div className="w-full mt-4">
                    <div className="flex flex-row mx-auto  w-full flex flex-row items-center max-w-fit">
                      {scoreData && <Tournesol score={scoreData?.score} />}
                    </div>
                  </div>
                </Skeleton>
              )}
            </Box>
          }
          rightSide={
            <Flex
              flex="2"
              flexDirection={"column"}
              className="p-4 overflow-y-auto"
              gap={2}
            >
              {getScoreError ? (
                <EmsAlert
                {
                  ...handleError(getScoreError)
                 }
                />
              ) : (
                <Skeleton isLoaded={!getScoreIsLoading}>
                  <Panel className="max-h-full min-h-full">
                    <Switcher
                      withDates={false}
                      units={[Unit.GES, Unit.DPE]}
                      className="h-full"
                    >
                      <div className="flex flex-col gap-6">
                        <div className="flex flex-col gap-4">
                          <div className="flex flex-col gap-4">
                            <div
                              className="uppercase"
                              style={{
                                color: COLORS.EMS_BLUE,
                                fontSize: "18px",
                                fontWeight: "bold",
                                textAlign: "center",
                              }}
                            >
                              Votre hbitation est classee (par le{" "}
                              {unit == "DPE" ? (
                                <Link
                                  href="https://www.ecologie.gouv.fr/diagnostic-performance-energetique-dpe"
                                  isExternal
                                >
                                  DPE <ExternalLinkIcon m="2px" />
                                </Link>
                              ) : (
                                <span>GES</span>
                              )}
                              )
                            </div>
                            <div className="flex flex-row justify-center">
                              {scoreData && (
                                <DpeGesSVG
                                  classe={
                                    unit == "DPE"
                                      ? scoreData?.dpe
                                      : scoreData?.ges
                                  }
                                  vertical={false}
                                  type={unit}
                                />
                              )}
                            </div>
                          </div>
                          <div className="flex flex-col gap-4">
                            <div
                              className="uppercase"
                              style={{
                                color: COLORS.EMS_BLUE,
                                fontSize: "18px",
                                fontWeight: "bold",
                                textAlign: "center",
                              }}
                            >
                              D&apos;apres votre consommation actuelle vous
                              seriez classE{" "}
                            </div>
                            <div className="flex flex-row justify-center">
                              {scoreData && (
                                <DpeGesSVG
                                  classe={
                                    unit == "DPE"
                                      ? scoreData?.dpeReal
                                      : scoreData?.gesReal
                                  }
                                  vertical={false}
                                  type={unit}
                                />
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="flex flex-row justify-center">
                          {scoreData && (
                            <DPEGES
                              dpeGes={
                                unit == "DPE" ? scoreData?.dpe : scoreData?.ges
                              }
                              myDpeGes={
                                unit == "DPE"
                                  ? scoreData?.dpeReal
                                  : scoreData?.gesReal
                              }
                              type={unit}
                            ></DPEGES>
                          )}
                        </div>
                      </div>
                    </Switcher>
                  </Panel>
                </Skeleton>
              )}
            </Flex>
          }
        />
      </Show>
      <Show below="md">
        <ResponsiveLayout>
          <Flex
            flex="2"
            py={2}
            px={2}
            flexDirection={"column"}
            className="overflow-y-auto"
            gap={2}
          >
            <Box flex="1" className="p-4 max-w-fit" overflow={"auto"}>
              <EcoCoachingIndicators
                meanBestHour={scoreData?.meanBestHour}
                meanSurplusHour={scoreData?.meanSurplusHour}
                meanWorstHour={scoreData?.meanWorstHour}
              />
              {getScoreError ? (
                <div className="max-h-fit">
                  <EmsAlert
                       {
                        ...handleError(getScoreError)
                       }
                  />
                </div>
              ) : (
                <Skeleton isLoaded={!getScoreIsLoading} className="mx-auto">
                  <div className="w-full mt-4">
                    <div className="flex flex-row mx-auto  w-full flex flex-row items-center max-w-fit">
                      {scoreData && <Tournesol score={scoreData?.score} />}
                    </div>
                  </div>
                </Skeleton>
              )}
            </Box>
            {getScoreError ? (
              <EmsAlert
              {
                ...handleError(getScoreError)
               }
              />
            ) : (
              <Skeleton isLoaded={!getScoreIsLoading}>
                <Panel className="max-h-full min-h-full">
                  <Switcher
                    withDates={false}
                    units={[Unit.GES, Unit.DPE]}
                    className="h-full"
                  >
                    <div className="flex flex-col gap-6">
                      <div className="flex flex-col gap-4">
                        <div className="flex flex-col gap-4">
                          <div
                            className="uppercase"
                            style={{
                              color: COLORS.EMS_BLUE,
                              fontSize: "18px",
                              fontWeight: "bold",
                              textAlign: "center",
                            }}
                          >
                            Votre hbitation est classee (par le{" "}
                            {unit == "DPE" ? (
                              <Link
                                href="https://www.ecologie.gouv.fr/diagnostic-performance-energetique-dpe"
                                isExternal
                              >
                                DPE <ExternalLinkIcon m="2px" />
                              </Link>
                            ) : (
                              <span>GES</span>
                            )}
                            )
                          </div>
                          <div className="flex flex-row justify-center">
                            {scoreData && (
                              <DpeGesSVG
                                classe={
                                  unit == "DPE"
                                    ? scoreData?.dpe
                                    : scoreData?.ges
                                }
                                vertical={false}
                                type={unit}
                              />
                            )}
                          </div>
                        </div>
                        <div className="flex flex-col gap-4">
                          <div
                            className="uppercase"
                            style={{
                              color: COLORS.EMS_BLUE,
                              fontSize: "18px",
                              fontWeight: "bold",
                              textAlign: "center",
                            }}
                          >
                            D&apos;apres votre consommation actuelle vous seriez
                            classE{" "}
                          </div>
                          <div className="flex flex-row justify-center">
                            {scoreData && (
                              <DpeGesSVG
                                classe={
                                  unit == "DPE"
                                    ? scoreData?.dpeReal
                                    : scoreData?.gesReal
                                }
                                vertical={false}
                                type={unit}
                              />
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="flex flex-row justify-center">
                        {scoreData && (
                          <DPEGES
                            dpeGes={
                              unit == "DPE" ? scoreData?.dpe : scoreData?.ges
                            }
                            myDpeGes={
                              unit == "DPE"
                                ? scoreData?.dpeReal
                                : scoreData?.gesReal
                            }
                            type={unit}
                          ></DPEGES>
                        )}
                      </div>
                    </div>
                  </Switcher>
                </Panel>
              </Skeleton>
            )}
          </Flex>
        </ResponsiveLayout>
      </Show>
    </>
  );
};
