/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from "react";
import { resetTopology } from "../reducers/topologyReducer";
import { ResponseType, topologyApi } from "../services/topology.service";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { chooseTopology } from "../reducers/topologyReducer";
import { store } from "../store/config";
import { selectAuth } from "../reducers/authReducer";
import { Skeleton, Spinner, Wrap } from "@chakra-ui/react";
import {
  getConnectedUserId,
  getLoadingDataErrorByStatus,
  handleError,
  installerRoles,
} from "../utils/toolbox";
import EmsAlert from "../components/EmsAlert";
import Site from "../components/Site";
import { MyToposPage } from "./MyToposPage";
export const RedirectPage: React.FC = () => {
  const [, setListeTopos] = useState<ResponseType[]>();
  const dispatch = useDispatch();
  const { useFindByOwnerMutation } = topologyApi;
  const [getListTopos, listToposResponse] = useFindByOwnerMutation();
  const auth = selectAuth(store.getState());
  const navigate = useNavigate();
  useEffect(() => {
    getListTopos({
      uri: "fluxTopology",
      body: { id: getConnectedUserId(auth.user?.token || "") + "" },
    })
      .unwrap()
      .then((res) => {
        if (res.length == 1) {
          dispatch(
            chooseTopology({
              id: res[0].id as string,
              batteryCapacity: res[0].capacity + "",
            })
          );
          navigate("/my-installation");
        } else {
          setListeTopos(res);
        }
      });
  }, [auth?.user]);

  useEffect(() => {
    dispatch(resetTopology());
  }, []);
  return listToposResponse.isError ? (
    <EmsAlert
    {
      ...handleError(listToposResponse.error)
     }
    />
  ) : listToposResponse.isLoading ? (
    <div className="flex flex-row justify-center items-center w-full h-full">
        <Spinner size='xl' />
    </div>
  ) : listToposResponse.data ? (
    listToposResponse.data.length == 0 ? (
      <div>Vous n&apos;avez pas de site !</div>
    ) : (
      <MyToposPage listTopos={listToposResponse.data} />
    )
  ) : (
    <></>
  );
};
