import React, { useEffect, useState } from "react";
import moment from "moment";
import { getSelectedTopo } from "../reducers/topologyReducer";
import { store } from "../store/config";
import { WeatherItemType } from "./WeatherDetails";
import { weatherApi } from "../services/weather.service";
import {
  displayWeatherIcon,
  formatDateHoursAndMinutes,
} from "../utils/toolbox";
import { useNavigate } from "react-router-dom";
import { Image } from "@chakra-ui/react";
export const WeatherIndicator: React.FC = () => {
  const [currentWeather, setCurrentWeather] = useState<WeatherItemType>();
  const navigate = useNavigate();
  const { useGetWeatherItemByTopoAndDateMutation } = weatherApi;
  const [getCurrentWeather] = useGetWeatherItemByTopoAndDateMutation();
  const [actualDate, setActualDate] = useState(moment().toDate());
  const topoId = getSelectedTopo(store.getState()).id
  useEffect(() => {
    if(topoId != ""){
      getCurrentWeather({
        date: moment().minutes(0).seconds(0).milliseconds(0).toDate(),
        fluxTopology: {
          id: Number(topoId),
        },
      })
        .unwrap()
        .then((res) => setCurrentWeather(res));
    }else {
      setCurrentWeather(undefined)
    }
  }, [topoId]); //refresh data every minute
  useEffect(() => {
    let timer: NodeJS.Timeout;
    const timout = setTimeout(() => {
      const now = moment().toDate();
      setActualDate(now);
      timer = setInterval(() => {
        const now = moment().toDate();
        setActualDate(now);
      }, 60000);
    }, (60 - new Date().getSeconds()) * 1000);

    return () => {
      window.clearInterval(timout);
      window.clearInterval(timer);
    };
  }, []);
  return (
    topoId ? 
    <div id="header" className="w-full cursor-pointer py-[7px]">
      <div className="max-w-sm h-full w-full ">
        <div
          className="flex flex-row items-center rounded-r-full md:w-3/4 bg-white bg-opacity-50 h-full border"
          onClick={() => {
            navigate("/weather");
          }}
        >
          <div className="py-2 flex flex-row justify-center items-center gap-4 w-3/4 bg-white rounded-r-full bg-opacity-100 h-full ">
            <div className="flex flex-col h-full items-center">
              <p className="font-bold">
                {formatDateHoursAndMinutes(actualDate)}
              </p>
              <p className="font-semibold">
                {currentWeather?.detailsDescription
                  ? currentWeather?.detailsDescription
                  : currentWeather?.description}
              </p>
            </div>
            <div className="">
              {currentWeather && currentWeather.weatherIconUrl && (
                <Image
                  src={
                    displayWeatherIcon(currentWeather.iconId) ||
                    currentWeather.weatherIconUrl
                  }
                  w={"12"}
                  h={"12"}
                  alt={currentWeather.description}
                />
              )}
            </div>
          </div>
          <div className="px-auto"></div>
        </div>
      </div>
    </div> : <div></div>
  );
};
