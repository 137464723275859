import React, { ReactNode } from "react";
import "../assets/scss/components/Panel.scss";
import { ReactComponent as ArrowTopRight } from "../assets/icons/ArrowTopRight.svg";
import { ReactComponent as Info } from "../assets/icons/Info.svg";
import { Link } from "react-router-dom";
import { Box } from "@chakra-ui/react";

interface PanelProps {
  children: ReactNode;
  title?: ReactNode | string;
  subclass?: string;
  link?: string;
  className?: string;
  withArrow?: boolean;
}

const Panel: React.FC<PanelProps> = ({
  children,
  title,
  link,
  className,
  withArrow = true,
}) => {
  return (
    <Box className={`panel ${className}`}>
      {title && (
        <div className="panel__title">
          <h3 className="mx-auto">{title}</h3>
          <Link to={link ?? "#"}>{withArrow && <ArrowTopRight />}</Link>
        </div>
      )}
      {children}
    </Box>
  );
};

export default Panel;
