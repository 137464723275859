/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from "react";
import { Box } from "@chakra-ui/react";
import "../assets/scss/components/AutonomieIndicator.scss";
import {
  formatDate,
  getNumberFormatter,
  isToday,
  symbolMap,
} from "../utils/toolbox";
import { displayUnit } from "../contexts/SwitcherContext";
import { AutonomyItem, Unit } from "../services/ems.service";
interface AutonomieIndicatorProps {
  data: any;
  autonomieValue?: string;
  avantinstallation?: string;
  avecInstallation?: string;
  gain?: string;
  period: string;
  date: Date;
  unit: string;
}

export const AutonomiePanel: React.FC<AutonomieIndicatorProps> = ({
  data,
  period,
  date,
  unit,
}) => {
  const [autonomieValuekWh, setAutonomieValuekWh] = useState<
    string | undefined
  >();
  const [autonomieValuePct, setAutonomieValuePct] = useState<
    string | undefined
  >();
  const [avantinstallation, setAvantinstallation] = useState<
    string | undefined
  >();
  const [avecInstallation, setAvecInstallation] = useState<
    string | undefined
  >();
  const [gain, setGain] = useState<string | undefined>();
  const [bawData, setBawData] = useState<
    { best: number; worst: number } | undefined | null
  >();
  const [width, setWidth] = useState<number>(0);

  const formatAutonomyData = (data: any, period: string, unit: string) => {
    const euroData = data[period]?.[Unit.EURO.toUpperCase()];
    const kwhData = data[period]?.[Unit.KWH.toUpperCase()];
    const pctData = data[period]?.[Unit.PCT.toUpperCase()];

    if (!euroData || !kwhData || !pctData) return;

    setAutonomieValuekWh(
      getNumberFormatter().format(kwhData.myAutonomy * 1000) + "Wh"
    );
    setAutonomieValuePct(getNumberFormatter().format(pctData.myAutonomy));
    if (unit.toUpperCase() == Unit.KWH.toUpperCase()) {
      setAvantinstallation(
        getNumberFormatter().format(
          kwhData.myAutonomieBeforeInstallation * 1000
        ) + "Wh"
      );
      setAvecInstallation(
        getNumberFormatter().format(
          kwhData.myAutonomieWithInstallation * 1000
        ) + "Wh"
      );
    } else if (unit.toUpperCase() == Unit.EURO.toUpperCase()) {
      setAvantinstallation(
        getNumberFormatter().format(euroData.myAutonomieBeforeInstallation) +
          displayUnit(unit, symbolMap)
      );
      setAvecInstallation(
        getNumberFormatter().format(euroData.myAutonomieWithInstallation) +
          displayUnit(unit, symbolMap)
      );
    } else {
      setAvantinstallation(
        getNumberFormatter().format(pctData.myAutonomieBeforeInstallation) +
          displayUnit(unit, symbolMap)
      );
      setAvecInstallation(
        getNumberFormatter().format(pctData.myAutonomieWithInstallation) +
          displayUnit(unit, symbolMap)
      );
    }
  };

  const formatGainData = (data: any, period: string) => {
    const myGain = data[period]?.[Unit.EURO.toUpperCase()]?.myGain;

    if (myGain) {
      setGain(
        getNumberFormatter().format(myGain) + displayUnit("Euro", symbolMap)
      );
    }
  };

  const formatBawData = (data: any, period: string) => {
    const baw = data[period]?.bawData;

    if (baw?.worst && baw?.best) {
      const max = baw.best * 100;
      const min = baw.worst * 100;
      const autonomyPct = Number(data[period]?.["myAutonomyPct"]?.toFixed(1));

      if (min === max || autonomyPct < min) {
        setWidth(50);
      } else {
        const pct = ((autonomyPct - min) / (max - min)) * 100;
        setWidth(Math.floor(pct));
      }
      setBawData(baw);
    }
  };

  useEffect(() => {
    if (!data || !data[period]) return;

    formatAutonomyData(data, period, unit);
    formatGainData(data, period);
    formatBawData(data, period);
  }, [data, period, unit]);

  return (
    <div className="graph-autonomie">
      <div className="graph-autonomie__pourcentage ">
        <div>{autonomieValuekWh}</div>
        <h1 className="text-sm">d&apos;autonomie</h1>
        {autonomieValuePct} %
      </div>
      <div className="graph-autonomie__detail-pourcentage ">
        <div className="graph-autonomie__detail-pourcentage__pourcentage-min  ">
          {bawData?.worst && getNumberFormatter().format(bawData?.worst * 100)}%
        </div>
        <div className="relative w-full h-6 rounded-full overflow-hidden border my-auto mx-2 border">
          <Box
            bgGradient="linear(to-r, red.500,yellow.300,  yellow.300, green.300)"
            className="absolute top-0 left-0 h-full bg-green-500 w-full"
          ></Box>
          <div
            className={`absolute top-0  max-w-fit w-full h-full flex items-center justify-center text-black font-bold`}
            style={{
              left: `${width}%`,
              border: "2px solid black",
            }}
          ></div>
        </div>
        <div className="graph-autonomie__detail-pourcentage__pourcentage-max  ">
          {bawData?.best && getNumberFormatter().format(bawData?.best * 100)}%
        </div>
      </div>
      <div className="graph-autonomie__titre-taux-equipement-equivalent ">
        TAUX D&apos;EQUIPEMENT EQUIVALENT
      </div>
      <div className="graph-autonomie__facture">
        <div className="graph-autonomie__facture-sans-installation">
          <div className="graph-autonomie__facture-sans-installation__title ">
            SANS INSTALLATION
          </div>
          <div className="graph-autonomie__facture-sans-installation__value">
            {avantinstallation}
          </div>
        </div>
        <div className="graph-autonomie__facture-avec-installation ">
          <div className="graph-autonomie__facture-avec-installation__title">
            AVEC INSTALLATION
          </div>
          <div className="graph-autonomie__facture-avec-installation__value">
            {avecInstallation}
          </div>
        </div>
      </div>

      <div className="graph-autonomie__bilan">
        <div>
          <div className="graph-autonomie__bilan__date">
            {isToday(date) ? "" : "En "}
            {formatDate(date, period)}
          </div>
          <div className="graph-autonomie__bilan__description">
            MON INSTALLATION M&apos;A FAIT GAGNER :{" "}
          </div>
          <div className="graph-autonomie__bilan__value">{gain}</div>
        </div>
      </div>
    </div>
  );
};

export default AutonomiePanel;
