import { ReactNode } from "react";
import Panel from "./Panel";
import { Switcher } from "./Switcher";
import { Skeleton } from "@chakra-ui/react";
import EmsAlert from "./EmsAlert";
import {
  getLoadingDataErrorByStatus,
  handleError,
  YYYY_MM_DD_HH_MM_SS,
} from "../utils/toolbox";
import { DataCategory, useGetAutonomieIndicatorDataQuery } from "../services/ems.service";
import { useSwitcherContext } from "../contexts/SwitcherContext";
import { getSelectedTopo } from "../reducers/topologyReducer";
import { store } from "../store/config";
import AutonomiePanel from "./AutonomiePanel";
import moment from "moment";

export const AutonomieIndicator: React.FC = () => {
  const { unit, setUnit, period, setPeriod, switcherDate, setSwitcherDate } =
    useSwitcherContext();
  const selectedTopoId = getSelectedTopo(store.getState()).id;
  const {
    data: autonomieData,
    isError: autonomieIsError,
    error: autonomieError,
    isFetching: autonomieIsFetching,
  } = useGetAutonomieIndicatorDataQuery({
    date: moment(switcherDate).format(YYYY_MM_DD_HH_MM_SS),
    topologyId: selectedTopoId
  });
  const SkeletonContent = ({
    isLoading,
    children,
  }: {
    isLoading: boolean;
    children: ReactNode;
  }) => (
    <Skeleton isLoaded={!isLoading} className="h-full max-h-full min-h-full">
      {children}
    </Skeleton>
  );
  const ErrorAlert = ({
    title,
    description,
  }: {
    title: string;
    description: string;
  }) => (
    <EmsAlert status="error" title={title} message={description || ""} />
  );
  return (
    <Panel
      title="Mon autonomie"
      withArrow={false}
      className="w-full md:max-w-fit"
    >
      <Switcher>
        {autonomieIsError ? (
          <ErrorAlert
            title={
              handleError(autonomieError).title
            }
            description={
              handleError(autonomieError).message
            }
          />
        ) : (
          <SkeletonContent isLoading={autonomieIsFetching}>
            <AutonomiePanel
              date={switcherDate}
              data={autonomieData}
              period={period}
              unit={unit}
            />
          </SkeletonContent>
        )}
      </Switcher>
    </Panel>
  );
};
