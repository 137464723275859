import { Skeleton } from "@chakra-ui/react";
import moment from "moment";
import React, { memo, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ReactComponent as BatteryDown } from "../assets/icons/ifpen-battery-down-white.svg";
import { ReactComponent as BatteryUp } from "../assets/icons/ifpen-battery-up.svg";
import "../assets/scss/components/BatteryGraphs.scss";
import { useSwitcherContext } from "../contexts/SwitcherContext";
import { getSelectedTopo } from "../reducers/topologyReducer";
import {
  Measure,
  MeasuredDataDto,
  MonitoringItem,
  Unit,
  useMonitoringQuery
} from "../services/ems.service";
import { store } from "../store/config";
import {
  COLORS,
  TimeLabels,
  YYYY_MM_DD_HH_MM_SS,
  createDataset,
  getFormatedDatetime
} from "../utils/toolbox";
import EmsAlert from "./EmsAlert";
import LabeledBarChart from "./LabeledBarChart";
import Panel from "./Panel";
import { Switcher } from "./Switcher";
const BatteryGraphsComponent: React.FC = () => {
  const [dataBattery, setDataBattery] = useState<any>();
  const [dataSocBattery, setDataSocBattery] = useState<any>();

  //pBatGlobal
  const labelsBatt: TimeLabels[] = [];
  const pBatGlobalCharge: Measure[] = [];
  const pBatGlobalDeCharge: Measure[] = [];

  //socBatGlobal

  const labelsSocBatGlobal: TimeLabels[] = [];
  const socBatGlobal: Measure[] = [];

  const { unit, setUnit, period, setPeriod, switcherDate, setSwitcherDate } =
    useSwitcherContext();
  const navigate = useNavigate();
  const selectedTopoId = getSelectedTopo(store.getState()).id;
  useEffect(() => {
    if (selectedTopoId == "") {
      navigate("/redirect");
    }
  }, [selectedTopoId]);
  //const monitoringResponse =  useGetMonitoringDataQuery(switcherDate)
  const {
    data: historyData,
    isError: historyDataIsError,
    isFetching: historyDataIsFetching,
  } = useMonitoringQuery(
    {
      date: moment(switcherDate).format(YYYY_MM_DD_HH_MM_SS),
      topologyId: selectedTopoId,
    },
    { refetchOnMountOrArgChange: true }
  );

  useEffect(() => {
    setSwitcherDate(new Date());
    setPeriod("day");
    setUnit(Unit.KWH);
  }, [selectedTopoId]);

  useEffect(() => {
    //history
    if (historyData) {
      // Vérifications d'existence plus robustes
      if (
        !historyData ||
        !historyData[period] ||
        !(historyData[period] as MonitoringItem)[unit.toUpperCase()]
      )
        return;

      const dataByPeriod = (historyData[period] as MonitoringItem)[
        unit.toUpperCase()
      ];
      // Filtrage et traitement des données en un seul passage
      [...(dataByPeriod["pBatGlobal"] as MeasuredDataDto[])]
        .sort((item1, item2) => {
          // Comparaison des dates de manière plus sûre et efficace
          const date1 = new Date(item1.time);
          const date2 = new Date(item2.time);
          return date1.getTime() - date2.getTime();
        })
        .forEach((item) => {
          // Appel du formatteur si défini
          getFormatedDatetime(item, period, labelsBatt);
          if (item.measure < 0) {
            pBatGlobalCharge.push({
              measure: 0,
              time: item.time,
              dataCategory: item.dataCategory,
            });
          } else {
            pBatGlobalCharge.push({
              measure: item.measure ?? 0,
              time: item.time,
              dataCategory: item.dataCategory,
            });
          }
        });
      // Filtrage et traitement des données en un seul passage
      [...(dataByPeriod["pBatGlobal"] as MeasuredDataDto[])]
        .sort((item1, item2) => {
          // Comparaison des dates de manière plus sûre et efficace
          const date1 = new Date(item1.time);
          const date2 = new Date(item2.time);
          return date1.getTime() - date2.getTime();
        })
        .forEach((item) => {
          if (item.measure > 0) {
            pBatGlobalDeCharge.push({
              measure: 0,
              time: item.time,
              dataCategory: item.dataCategory,
            });
          } else {
            pBatGlobalDeCharge.push({
              measure: item.measure ?? 0,
              time: item.time,
              dataCategory: item.dataCategory,
            });
          }
        });

      [...(dataByPeriod["socBatGlobal"] as MeasuredDataDto[])]
        .sort((item1, item2) => {
          // Comparaison des dates de manière plus sûre et efficace
          const date1 = new Date(item1.time);
          const date2 = new Date(item2.time);
          return date1.getTime() - date2.getTime();
        })
        .forEach((item) => {
          // Appel du formatteur si défini
          getFormatedDatetime(item, period, labelsSocBatGlobal);
          socBatGlobal.push({
            measure: item.measure,
            time: item.time,
            dataCategory: item.dataCategory,
          });
        });
        const graphBatt = {
          labels: labelsBatt,
          datasets: [
            createDataset(
              1,
              "Charge batterie",
              pBatGlobalCharge,
              BatteryUp,
              BatteryUp,
              COLORS.BATTERYUP,
              COLORS.BATTERYUP_OPACITY50
            ),
            createDataset(
              2,
              "Décharge batterie",
              pBatGlobalDeCharge,
              BatteryDown,
              BatteryDown,
              COLORS.BATTERYDOWN,
              COLORS.BATTERYDOWN_OPACITY50
            ),
          ],
        };
    
        const graphSocBatGlobal = {
          labels: labelsSocBatGlobal,
          datasets: [
            createDataset(
              1,
              "Etat de charge de la batterie",
              socBatGlobal,
              BatteryUp,
              BatteryUp,
              COLORS.EMS_BLUE_SEMI_LIGHT,
              COLORS.EMS_BLUE_SEMI_LIGHT
            ),
          ],
        }; 
        setDataBattery(graphBatt);
        setDataSocBattery(graphSocBatGlobal);
    }
   
  }, [switcherDate, historyData, period, unit]);

  return (
    <>
      {historyDataIsError ? (
        <EmsAlert
          status="error"
          title={"Erreur lors de la récupération des données"}
          message={""}
        ></EmsAlert>
      ) : (
        <>
          <Skeleton
            isLoaded={!historyDataIsFetching}
            className="h-full max-h-full min-h-full"
          >
            <Panel
              className="h-full max-h-full min-h-full"
              withArrow={false}
              title={
                <div className="text-center text-[#0488F9] md:mt-2 text-xl md:text-3xl font-extrabold">
                  {" "}
                  Potentiel de charge{" "}
                </div>
              }
            >
              <Switcher
                units={[Unit.KWH]}
                withDates={true}
                withPeriods={false}
                className="h-full"
              >
                <LabeledBarChart
                  data={dataSocBattery}
                  getDataForLabel={() => {}}
                  displayCols
                />
              </Switcher>
            </Panel>
          </Skeleton>
          <Skeleton
            isLoaded={!historyDataIsFetching}
            className="h-full max-h-full min-h-full"
          >
            <Panel
              className="h-full max-h-full min-h-full"
              withArrow={false}
              title={
                <div className="text-center text-[#0488F9] md:mt-2 text-xl md:text-3xl font-extrabold">
                  {" "}
                  Activites de la batterie{" "}
                </div>
              }
            >
              <Switcher
                units={[Unit.KWH]}
                withDates={true}
                withPeriods={false}
                className="h-full"
              >
                <LabeledBarChart
                  data={dataBattery}
                  getDataForLabel={() => {}}
                  displayCols
                />
              </Switcher>
            </Panel>
          </Skeleton>
        </>
      )}
    </>
  );
};
export const BatteryGraphs: React.FC = memo(BatteryGraphsComponent);
