import React from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { Box, Button, Text } from '@chakra-ui/react';

interface ErrorFallbackProps {
  error: Error;
  resetErrorBoundary: () => void;
}

const ErrorFallback: React.FC<ErrorFallbackProps> = ({ error, resetErrorBoundary }) => {
  return (
    <Box p={4} bg="red.100" borderRadius="md" boxShadow="lg">
      <Text color="red.600">Une erreur s'est produite : {error.message}</Text>
      <Button mt={4} colorScheme="teal" onClick={resetErrorBoundary}>
        Recharger la page
      </Button>
    </Box>
  );
};

export default ErrorFallback;
